
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <div v-if="subscription">
        <div>
          <h4 class="font-weight-bold"> Your {{subscription.group.name}} language subscription ${{subscription.amount | money_format}} USD  per month</h4>
          <p>Subscription current period began on <strong>{{subscription.current_period_start| date_time(1)}}</strong>. It {{subscription.status == 'active'? 'will expire' : 'ended'}} on:  <strong>{{subscription.current_period_end | date_time(1)}}.</strong></p>
          
          <div v-if="subscription.status == 'past_due'" class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Subscription expired!</h4>
            <p>Kindly remember to renew your subscription for uninterrupted access and continued benefits ahead</p>
            <hr>
            <button type="button" @click.prevent="renewSubscription()" class="btn mr-2 btn-primary" target="_blank">Renew now </button>
            <button type="button" @click.prevent="popupPaymentMethod = true" class="btn btn-success" target="_blank">Update payment method </button>
          </div>
        </div>
        <div class="card">
          <div class="card-header py-2 bg-white">
            <h5 class="mb-1">Your payment method</h5>
          </div>
          <div class="card-body">
            <dl class="row">
              <dt class="col-6 col-md-4">Card type</dt>
              <dd class="col-6"> {{subscription.credit_card.card_type}} </dd>
              <dt class="col-6 col-md-4">Last 4-digits</dt>
              <dd class="col-6"> {{subscription.credit_card.last4}} </dd>
              <dt class="col-6 col-md-4">Expiring date</dt>
              <dd class="col-6"> {{subscription.credit_card.exp_month}}/{{subscription.credit_card.exp_year}} </dd>
            </dl>
          </div>
        </div>
        <div class="card">
          <div class="card-header py-2 bg-white">
            <h5 class="mb-1">Payment History</h5>
          </div>
          <div class="card-body">
            <div class="row gy-3 justify-content-between">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-nowrap mb-0">
                    <thead class="table-light">
                      <tr>
                        <th scope="col">Reference</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(payment, inIndex) in subscription.payments" :key="inIndex">
                        <td>{{payment.reference }}</td>
                        <td>{{payment.status }}</td>
                        <td>{{payment.payment_date | date_time(1) }}</td>
                        <td>₦{{payment.amount | money_format}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-if="isLoading" />
</div>
</template>

<script>

import IsLoading from '@/components/IsLoading.vue'
export default {
  components:{
    IsLoading,
  },
  data(){
    return {
      popupPaymentMethod: false,
      isLoading: true,
      subscription: null,
      config:{}
    }
  },
  computed:{
    activeUser(){
      return this.$store.state.AppActiveUser
    },
  },
  methods:{
    initializeAll(){
      this.popupPaymentMethod = false
    },
    cancelSubscription(subId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to cancel this subscription",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post(`/subscriptions/${subId}/cancel`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("UPDATE_SUBSCRIPTION", response.data.data)
            }
          })
        }
      });
    },
    renewSubscription() {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to renew this subscription",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: '#800020',
        cancelButtonColor: '#565656',
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue", true)
          this.$http.post(`/subscriptions/${this.subscription.id}/renew`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              Object.assign(this.subscription, response.data.data)
            }
          })
        }
      });
    },
    updateSubscription(item){
      Object.assign(this.subscription, item)
    },
    fetchSubscription(){
      this.isLoading = true
      this.$http.get(`/subscriptions/${this.$route.params.subId}/show`)
      .then((response) => {
          this.isLoading =  false
          if(response.data.success){
            this.subscription = response.data.data
          }
      })
    }
  },
  created(){
    this.fetchSubscription()
  }
}

</script>

<style lang="scss">

</style>
